import { Component, EventEmitter, Input, OnInit, Output, input } from '@angular/core';

@Component({
    selector: 'hiji-top-buttons',
    templateUrl: './top-buttons.component.html',
    styleUrls: ['./top-buttons.component.scss'],
    standalone: false
})
export class TopButtonsComponent implements OnInit {
  @Input()
  rootLink: string;
  @Input()
  endLink: string;
  @Input()
  itemLink: string;
  @Input()
  backLink: string;
  @Input()
  edit: boolean;
  @Input()
  add: boolean;
  @Input()
  hideBackButton: boolean;
  @Output()
  duplicate = new EventEmitter<void>();
  allowEdit = input(true);
  allowDuplicate = input(true);

  isAdminOrGestionnaire: boolean;

  constructor() {}

  ngOnInit(): void {}
}
