<div class="wavy">
  <!--
  <div class="wave-bl">
    <svg width="100" height="100" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m0.0077675 100-0.0077674-100 20.482 0.61342c7.4813 0.92494 11.995 8.7182 11.995 8.7182 3.6423 6.6914 3.0112 15.704 3.0112 15.704l0.52771 25.764c0.08868 10.834 3.0829 18.49 7.4213 20.3 2.4491 1.0218 5.1473 2.5049 7.5405 2.7847 6.6058 0.7722 11.423 0.22514 11.423 0.22514 11.943-0.17167 18.366 0.4781 22.485 1.0115 4.4838 0.5808 6.3421 0.72568 8.7229 2.4821 3.1254 3.6725 4.081 7.1508 4.7592 10.431 1.3499 6.5282 1.6322 11.839 1.6322 11.839z"
        fill="#fece48"
      />
      <path
        d="m2.9104e-8 100 0.12432-64.35 11.624 0.38184c12.057 0.12393 12.02 15.044 12.02 15.044l1.0567 24.895c0.59442 12.371 13.473 11.913 13.473 11.913 15.939-0.78909 24.811 0.48364 27.013 2.6728 2.9621 3.1055 3.302 7.2038 3.6986 9.3166z"
        fill="#feba01"
      />
    </svg>
  </div>
  <div class="wave-tr">
    <svg width="100" height="45" viewBox="0 0 26.458 11.906" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m7.1778e-8 2.4847e-8c1.8273 8.1066 7.8599 7.9724 9.5159 8.4496l11.274 0.034915c2.9172 0.17458 5.07 2.3743 5.656 3.4217l0.012546-11.906z"
        fill="#fece48"
      />
      <path
        d="m26.458 2.4847e-8h-22.751c0.80438 7.5568 7.2025 8.3242 8.3312 8.4496l10.471-0.10475c2.18-0.15939 3.0089 0.29845 3.9458 0.63285z"
        fill="#feba01"
      />
    </svg>
  </div>-->
  <div class="title">
    <ng-content select="[title]"></ng-content>
  </div>
  <div class="buttons">
    <ng-content select="[buttons]"></ng-content>
  </div>
</div>

<div class="content">
  <section class="flex-grow-0 overflow-auto"><ng-content></ng-content></section>
  <div class="footer"><ng-content select="[footer]"></ng-content></div>
</div>
