import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'hiji-wavy-bg',
    templateUrl: './wavy-bg.component.html',
    styleUrls: ['./wavy-bg.component.scss'],
    standalone: false
})
export class WavyBgComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
